import { NgClass } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ButtonComponent } from '../button/button.component';
import { ContasComponent } from '../../modules/contas/contas.component';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-modal-delete-account',
  standalone: true,
  imports: [NgClass, ButtonComponent],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialog, 
    private contas: ContasComponent,
    private readonly store: Store<{
      contas: any;
      forms: any;
    }>,
  ) {}

  accountInfo = {
    name: '',
    id: 0,
    fullName: '',
    status: ''
  }

  ngOnInit(): void {
    this.store.select('contas').subscribe(
      res => {
        this.accountInfo = {
          name: res.name,
          id: res.id,
          fullName: res.fullName,
          status: res.status
        }
      }
    );
  }

  @Output() handleRemoveAccountEmitter = new EventEmitter();

  closeModal(): void {
    this.dialogRef.closeAll();
  }

  async handleDeleteAccount() {
    this.contas.removeAccount(this.accountInfo.id);
    this.closeModal();
  }
}
