import { NgIf } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { formVisibilityAction } from '../../../../store/components/forms/forms.actions';
import { ButtonComponent } from '../../button/button.component';

@Component({
  selector: 'app-header-sub',
  standalone: true,
  imports: [ButtonComponent, NgIf],
  templateUrl: './header-sub.component.html',
  styleUrl: './header-sub.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderSubComponent {
  @Input() hasActionButton!: boolean;
  @Input() pageName!: string;

  constructor(
    private readonly store: Store<{
      forms: any;
    }>
  ) {}

  handleFormActions() {
    this.store.dispatch(formVisibilityAction({ isVisible: true }));
  }
}
