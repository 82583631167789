<div class="content">
  <div class="cards">
    <div class="box-add-new-account" aria-hidden="true" (click)="handleAddNewAccount()">
      <img src="../../../assets/images/icons/icon-add-new-account.svg" alt="adicionar conta">
      <h5>ADICIONAR CONTA</h5>
    </div>
  @for (account of dataAccounts$ | async; track account.id) {
    <app-card
    [name]="account.name || ''"
    [status]="account.status"
    [id]="account.id"
    (handleFormActionsEmitter)="handleFormDetails(account)"
    (handleDeletetActionsEmitter)="handleDeleteDetails(account)"
  ></app-card>
   }
  </div>
  @if(isLoading$ | async) {
    <div class="container-logo">
      <app-loader-page></app-loader-page>
    </div>
  }
</div>