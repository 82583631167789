import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-input-switch',
  standalone: true,
  imports: [MatSlideToggleModule, ReactiveFormsModule],
  templateUrl: './input-switch.component.html',
  styleUrl: './input-switch.component.scss',
})
export class InputSwitchComponent {
  @Input() text = '';
  @Input() formCtrl = (new FormControl() as AbstractControl) || FormControl;

  get control(): FormControl {
    return this.formCtrl as FormControl;
  }
}
