import { Component, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { GlobalEnum } from '../../shared/models/enums/global';
import { ProfileDropdownComponent } from '../profile-dropdown/profile-dropdown.component';
import { HeaderSubComponent } from './header-sub/header-sub.component';
import { AccountsDropdownComponent } from '../accounts-dropdown/accounts-dropdown.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    HeaderSubComponent,
    ProfileDropdownComponent,
    AccountsDropdownComponent,
    RouterLink,
    MatIconModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  // Enums
  capivaraLogoText = GlobalEnum.capivaraLogoText;
  notificationsText = GlobalEnum.notificationsText;
}
