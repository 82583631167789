import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '../../../services/accounts.service';
import { APIResponseDto } from '../../shared/models/dtos';
import { ButtonComponent } from '../button/button.component';
import { environment } from '../../../environments/environment';
import { BotKeyResponseDto } from './dto/bot-key.response.dto';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-whats-app-access',
  standalone: true,
  imports: [NgClass, ButtonComponent],
  templateUrl: './modal-whats-app-access.component.html',
  styleUrl: './modal-whats-app-access.component.scss'
})
export class ModalWhatsAppAccessComponent implements OnInit {
  public botKeyResponse: BotKeyResponseDto = {
    botKey: '',
  };

  constructor(
    private dialogRef: MatDialog,
    private accountService: AccountService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.accountService.getBotKey().subscribe((response: APIResponseDto<BotKeyResponseDto>) => {
      if (response?.body) {
        this.botKeyResponse = response.body;
      }
    });
  }

  closeModal(): void {
    this.dialogRef.closeAll();
  }

  sendUserToWhatsApp(): void {
    const phoneNumber = environment.WHATSAPP_NUMBER;
    const whatsappUrl = `https://wa.me/send/?phone=${phoneNumber}&text=Meu token de acesso é ${this.botKeyResponse.botKey}`;

    window.open(whatsappUrl, '_blank');
  }

  onClipboardCopy(valueToCopy: string) {
    navigator.clipboard.writeText(valueToCopy);
    this.toastrService.success('Conteúdo copiado para a área de transferência');
  }
}
