export enum InputEnum {
  enterYourEmailText = 'Insira seu e-mail',
  enterYourPasswordText = 'Insira sua senha',
  enterYourRegisterCodeText = 'Insira seu código de cadastro',
  errorText = 'Erro',
  incorrectCredentialsText = 'Credenciais inválidas.',
  passwordVisibilityOffText = 'Visibilidade senha desligada',
  setSurnameText = 'Defina um apelido para identificar sua conta:',
  whichAccountNameText = 'Como vamos chamar essa conta?',
}
