import { state, style, trigger } from '@angular/animations';
import { NgClass, NgIf } from '@angular/common';
import { Component, Input, Renderer2, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';
import { DateTime } from 'luxon';	

import { formVisibilityAction } from '../../../../store/components/forms/forms.actions';
import { AuthEnum } from '../../../shared/models/enums/auth.enum';
import { ButtonEnum } from '../../../shared/models/enums/button.enum';
import { FormsEnum } from '../../../shared/models/enums/forms.enum';
import { GlobalEnum } from '../../../shared/models/enums/global';
import { InputEnum } from '../../../shared/models/enums/input.enum';
import { AddNewComponent } from '../../add-new/add-new.component';
import { ButtonComponent } from '../../button/button.component';
import { InputSwitchComponent } from '../input-switch/input-switch.component';
import { InputComponent } from '../input/input.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../modal-delete-account/modal.component';
import { ContasComponent } from '../../../modules/contas/contas.component';
import { ToastrService } from 'ngx-toastr';
import { resetDetailFormAction } from '../../../../store/contas/contas.actions';
import { formatISODate } from '../../../shared/utils/date';

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatIconModule,
    InputComponent,
    InputSwitchComponent,
    ButtonComponent,
    AddNewComponent,
    FormsModule,
    ContasComponent
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
  animations: [
    // Slide to Open/Close
    trigger('slideToggle', [
      state(
        'slideOpen',
        style({
          transform: 'translateX(0)',
          transition: 'ease-in-out 0.3s',
          opacity: 1,
        })
      ),
      state(
        'slideClose',
        style({
          transform: 'translateX(477px)',
          transition: 'ease-in-out 0.3s',
          opacity: 0,
        })
      ),
    ]),
    trigger('fadeToggle', [
      state(
        'fadeOpen',
        style({
          transition: 'ease-in-out 0.3s',
          opacity: 1,
        })
      ),
      state(
        'fadeClose',
        style({
          transition: 'ease-in-out 0.3s',
          opacity: 0,
          zIndex: -1,
        })
      ),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FormComponent {
  @Input() isFormOpened!: boolean;
  @Input() formType!: string;
  @Input() data = {
    externalId: 0,
    name: "",
    id: 0,
    status: "",
    fullName: "",
    email: "",
    externalRegistratedAt: "",
    createdAt: "",
    updatedAt: "",
    marketplace: {
      id: 0,
      name: ""
    },
    type: ""
  };

  public accountDetail!: boolean;
  public accountDetailForm!: FormGroup;
  public addNewForm!: FormGroup;

  isActiveClassName = '--is-opened';
  isClosingClassName = '--is-closing';
  updateAtFormatted = '';
  inputName = '';

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private readonly store: Store<{
      contas: any;
      forms: any;
    }>,
    private dialogRef: MatDialog,
    private toastr: ToastrService
  ) { }

  contas = inject(ContasComponent);
  isFormOpened$ = this.store.select('forms').pipe(map((v) => v.isVisible));

  // Enums
  authorizeConnectionMLText = AuthEnum.authorizeConnectionMLText;
  formAccountDetailText = FormsEnum.formAccountDetailText;
  formAddNewText = FormsEnum.formAddNewText;
  goBackCloseFormText = FormsEnum.goBackCloseFormText;
  goBackText = GlobalEnum.goBackText;
  accountDetailText = FormsEnum.accountDetailText;
  synchronizeText = FormsEnum.synchronizeText;
  connectMlText = ButtonEnum.connectMlText;
  enterNoLinkAccountText = ButtonEnum.enterNoLinkAccountText;
  enterText = ButtonEnum.enterText;
  setSurnameText = InputEnum.setSurnameText;
  enterYourEmailText = InputEnum.enterYourEmailText;
  enterYourPasswordText = InputEnum.enterYourPasswordText;
  enterYourRegisterCodeText = InputEnum.enterYourRegisterCodeText;
  hintOutlineText = GlobalEnum.hintOutlineText;
  linkAccountsLaterText = AuthEnum.linkAccountsLaterText;
  openInNewText = ButtonEnum.openInNewText;
  whichStoreWouldYouLinkText = AuthEnum.whichStoreWouldYouLinkText;
  whichAccountNameText = InputEnum.whichAccountNameText;

  handleOpenModalDelete() {
    this.dialogRef.closeAll();
    this.dialogRef.open(ModalComponent);
  }

  ngOnInit() {
    this.inputName = this.data.name;
    this.openForm();
    this.updateAtFormatted = formatISODate(this.data.updatedAt, "dd/MM/yyyy 'às' HH:mm:ss");

    // FormGroup de detalhe da conta.
    this.accountDetailForm = this.fb.group({
      rank: false,
      stock: false,
    });
  }

  updateName(name: string, id: any) {
    this.contas.updateNameAccountMl(name, id);
    setTimeout(() => {
      this.closeForm();
    }, 200);
  }

  deleteAccount(id: number) {
    this.contas.removeAccount(id);
    this.closeForm();
  }

  syncAccount(storeId: any) {
    this.contas.syncAccount(storeId);
  }

  /**
   * @description
   * Obtém o status do formulário vindo da store.
   * Abre o formulário e adiciona a classe modificadora "--is-opened" ao body.
   */
  openForm() {
    this.isFormOpened$
      .pipe(take(1))
      .subscribe(() =>
        this.renderer.addClass(document.body, this.isActiveClassName)
      );
  }

  /**
   * @description
   * Fecha o formulário removendo a classe "--is-opened" e adiciona a classe "--is-closing" ao body.
   * Em 200 milisegundos, remove a classe "--is-closing",
   * limpa o formulário e define a visibilidade do form para false.
   */
  closeForm() {
    this.renderer.removeClass(document.body, this.isActiveClassName);
    this.renderer.addClass(document.body, this.isClosingClassName);

    setTimeout(() => {
      this.renderer.removeClass(document.body, this.isClosingClassName);
      this.store.dispatch(formVisibilityAction({ isVisible: false }));
      this.store.dispatch(resetDetailFormAction());
    }, 200);
  }

  convertToLocalTime(dateString: string) {
    const date = new Date(dateString);

    const formatter = new Intl.DateTimeFormat(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    });
    return formatter.format(date);
  }

  ngOnDestroy() {
  }
}
