import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { InputEnum } from '../../../shared/models/enums/input.enum';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    NgIf,
  ],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class InputComponent {
  @Input() autocomplete?: string = 'off';
  @Input() auxiliaryText = '';
  @Input() disabled = false;
  @Input() emailValidation = false;
  @Input() formCtrl = (new FormControl() as AbstractControl) || FormControl;
  @Input() id = '';
  @Input() isValidCredentials!: boolean;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() type: 'text' | 'password' | 'number' | 'email' = 'text';
  @Output() keyupEventEmitter = new EventEmitter();
  @Input() iconInput!: string;

  // Enums
  incorrectCredentialsText = InputEnum.incorrectCredentialsText;
  errorText = InputEnum.errorText;

  get control(): FormControl {
    return this.formCtrl as FormControl;
  }

  ngOnInit(): void {
    if (this.emailValidation) {
      this.control.addValidators(
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      );
    }
  }

  focusoutInput() {
    // TODO: Implement validations
  }

  keyupInput() {
    this.keyupEventEmitter.emit();
  }
}
