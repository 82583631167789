<div class="modal-whats-app-access">
    <div class="modal-header">
        <p>Acesso via Whatsapp</p>
        <div aria-hidden="true" (click)="closeModal()">X</div>
    </div>
    <div class="modal-content">
        <div class="modal-content-info">
            <h4>
                Aqui está o seu token de acesso:
            </h4>

            @if (!botKeyResponse.botKey) {
                <div><span class="spinner"></span></div>
            } @else {
                <div class="bot-key">
                    {{botKeyResponse.botKey}}
                    <img (click)="onClipboardCopy(botKeyResponse.botKey)" class="clipboard" src="../../../../assets/images/icons/clipboard.svg" alt="">
                </div>
            }

            <h4>Para acessar as informações da sua conta:</h4>

            <ul>
                <li>Abra o WhatsApp: Inicie a conversa com o nosso assistente inteligente.</li>
                <li>Envie o token: Digite e envie o token acima na conversa com o assistente quando for solicitado.</li>
            </ul>

            <strong class="disclaimer">
                Com o token, você poderá conversar com o assistente inteligente e acessar todos os dados da sua conta de
                forma rápida e segura.
            </strong>
        </div>

        <div class="modal-content-btn-container">
            <div aria-hidden="true" (click)="sendUserToWhatsApp()">
                <app-button text="Iniciar conversa" />
            </div>
        </div>
    </div>
</div>