
<div class="profile-dropdown" [ngClass]="{ '--is-visible': isDropDownVisible }">
  <div class="profile-dropdown__head">
    <div class="profile-dropdown__head__avatar">
      <img src="../../../assets/images/icons/all-accounts-header.svg" alt="avatar" />
    </div>
    <div class="profile-dropdown__head__label">
      <button (click)="toggleDropDown()" type="button" [attr.aria-label]="dropDownText">
        <span>{{ dropDownText }}</span>
        <mat-icon aria-hidden="false" fontIcon="keyboard_arrow_down"></mat-icon>
      </button>
    </div>
  </div>
  <div class="profile-dropdown__body" *ngIf="isDropDownVisible">
    <nav class="profile-dropdown__body__nav" aria-labelledby="profile-dropdown-navigation">
      <div class="stores">
        @if(stores && stores.length > 0) {
          @for(store of stores; track $index) {
            <div class="checkbox">
              <input 
                [id]="store.id" 
                type="checkbox"   
                (change)="selectStore(store)" 
                [value]="store.id.toString()" 
                [checked]="storeService.selectedStores.includes(store)"
              />
              <label [for]="store.id" class="profile-dropdown__body__nav__label">
                {{ store.name }}
              </label>
            </div>
          }
        }
      </div>
    </nav>
  </div>
</div>
