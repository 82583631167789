import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  Injectable,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Store } from '@ngrx/store';
import { formVisibilityAction } from '../../../store/components/forms/forms.actions';
import { formDetailAction } from '../../../store/contas/contas.actions';
import { CardComponent } from '../../components/card/card.component';
import { FormComponent } from '../../components/forms/form/form.component';
import { HeaderSubComponent } from '../../components/header/header-sub/header-sub.component';
import { HeaderComponent } from '../../components/header/header.component';
import { MenuComponent } from '../../components/menu/menu.component';
import { BehaviorSubject, Observable, firstValueFrom, map, take } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../../../services/accounts.service';
import { LoaderPageComponent } from '../../components/loader-page/loader-page.component';
import { HttpStatusCode } from '@angular/common/http';

interface IAccountInfo {
  id?: string;
  name?: string | undefined;
  status?: string;
  user?: string;
  externalId?: string;
  fullName?: string;
  marketplace?: {
    id?: number;
    name?: string;
  };
  updatedAt?: string;
}
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-contas',
  standalone: true,
  imports: [
    MatSlideToggleModule,
    FormComponent,
    CardComponent,
    MenuComponent,
    HeaderComponent,
    HeaderSubComponent,
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    LoaderPageComponent,
  ],
  templateUrl: './contas.component.html',
  styleUrl: './contas.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ContasComponent implements OnInit {
  constructor(
    private store: Store<any>,
    private toastr: ToastrService,
    private dialogRef: MatDialog
  ) { }
  accountService = inject(AccountService);
  // accounts: IAccountInfo[] = [];

  private accounts = new BehaviorSubject<Array<any>>([]);
  dataAccounts$ = this.accounts.asObservable();
  private isLoading = new BehaviorSubject<any>({});
  isLoading$ = this.isLoading.asObservable();

  updateLoading(isLoading: boolean): void {
    this.isLoading.next(isLoading);
  }

  ngOnInit() {
    const newAccountName = localStorage.getItem('UpdateAccountName') || '';
    if (newAccountName) {
      this.validateIfAccountHasBeenCreated();
    } else {
      this.getAccounts();
    }
  }

  updateAccountsObservable(accounts: any) {
    this.accounts.next(accounts);
  }

  isFormOpened$ = this.store.select('forms').pipe(map((v) => v.isVisible));

  payload!: IAccountInfo;

  validateIfAccountHasBeenCreated() {
    const accountInfoToChange = localStorage.getItem('AccountApproveML');
    const newAccountName = localStorage.getItem('UpdateAccountName');

    if (newAccountName && accountInfoToChange) {
      const accountInfoParsed = JSON.parse(accountInfoToChange);
      const { id } = accountInfoParsed;

      this.accountService.updateNameAccount(newAccountName, id).subscribe({
        next: (value) => {
          localStorage.removeItem('UpdateAccountName');
          this.toastr.success('Conta criada com sucesso!');
          this.getAccounts();
        },
        error: (err) => {
          this.toastr.error(
            'Ocorreu algum erro ao vincular conta do Mercado Livre. Tente novamente.'
          );
          localStorage.removeItem('UpdateAccountName');
          localStorage.removeItem('AccountApproveML');
          this.getAccounts();
        }
      });
    } else {
      this.getAccounts();
    }

    this.dialogRef.closeAll();
  }

  getAccounts(): void {
    this.updateLoading(true);
    this.accountService.getAccounts().subscribe((res: any) => {
      const { error } = res;

      if (error) {
        const { message } = res;
        this.toastr.error(message);
        this.updateLoading(false);
      } else {
        const { body } = res;

        // this.accounts = body.stores;
        this.updateAccountsObservable(body.stores);
        this.updateLoading(false);
      }
    });
  }

  public updateNameAccountMl(name: string, id: string) {
    this.updateLoading(true);
    this.accountService.updateNameAccount(name, id).subscribe((res: any) => {
      const { error } = res;

      if (error) {
        const { message } = res;
        this.toastr.error(message);
        this.updateLoading(false);
      } else {
        this.updateLoading(false);
        this.toastr.success('Nome da conta alterado com sucesso!');
        this.getAccounts();
      }
      this.updateLoading(false);
    });
  }

  public async removeAccount(id: any): Promise<void> {
    this.updateLoading(true);

    try {
      const { error, info } = await firstValueFrom(this.accountService.RemoveAccount(id));

      if (error) {
        if (info.status === HttpStatusCode.Forbidden) {
          this.toastr.error('Não é possível deletar essa conta no momento.');
        } else {
          this.toastr.error('Ocorreu um problema ao deletar a conta, tente novamente mais tarde.');
        }

        return;
      }

      this.accounts.subscribe((updatedAccounts) => {
        this.updateAccountsObservable(updatedAccounts);
      });
      this.toastr.success('Conta deletada com sucesso!');
      this.dialogRef.closeAll();
      window.location.reload();
    } finally {
      this.updateLoading(false);
    }
  }

  public async syncAccount(storeId: any) {
    this.toastr.info('Enviando solicitação de sincronização');


    try {
      const { error, info } = await firstValueFrom(this.accountService.SyncAccount(storeId));

      if (error) {
        if(info.status === HttpStatusCode.TooManyRequests) {
          this.toastr.warning('Limite de sincronização atingido, tente novamente mais tarde.');
          return;
        }

        if (info.status === HttpStatusCode.Forbidden) {
          this.toastr.error('Não é possível sincronizar essa conta no momento.');
          return;
        } else {
          this.toastr.error('Ocorreu um problema ao sincronizar a conta, tente novamente mais tarde.');
          return;
        }

        return;
      }

      this.toastr.success('Sua conta está sendo sincronizada, retorne em breve para verificar.');
      this.dialogRef.closeAll();
      this.getAccounts();
    } finally {
      this.updateLoading(false);
    }
  }

  handleFormDetails(item: any) {
    if (!!item?.id) {
      this.store.dispatch(formDetailAction(item));
      this.store.dispatch(formVisibilityAction({ isVisible: true }));
    }
  }

  handleDeleteDetails(item: any) {
    if (!!item?.id) {
      this.store.dispatch(formDetailAction(item));
    }
  }

  handleAddNewAccount() {
    this.store.dispatch(formVisibilityAction({ isVisible: true }));
  }
}
