import { NgClass } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ButtonComponent } from '../button/button.component';
import { InputSwitchComponent } from '../forms/input-switch/input-switch.component';
import { ModalComponent } from '../modal-delete-account/modal.component';
import { ContasComponent } from '../../modules/contas/contas.component';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [InputSwitchComponent, NgClass, ButtonComponent, ModalComponent, ContasComponent],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent {
  constructor(private dialogRef: MatDialog, private contas: ContasComponent) {
  }

  @Input() name!: string;
  @Input() status!: string | undefined;
  @Input() id: string | undefined;
  @Output() handleFormActionsEmitter = new EventEmitter();
  @Output() handleDeletetActionsEmitter = new EventEmitter();

  openModalDialog(event: Event): void {
    this.dialogRef.open(ModalComponent);
    this.handleDeletetActionsEmitter.emit(event);
  }

  handleFormActions(event: Event) {
    this.handleFormActionsEmitter.emit(event);
  }

  syncAccount() {
    this.contas.syncAccount(this.id);
  }
}
