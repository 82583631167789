export enum AuthEnum {
  authorizeConnectionMLText = 'Para vincular suas contas autorize a conexão no Mercado Livre:',
  goBackToLoginText = 'Voltar para o login',
  headingText = 'heading',
  letsStartText = 'Vamos começar?',
  linkAccountsLaterText = 'Você pode vincular suas contas posteriormente',
  linkAccountsText = 'Vincular contas',
  logToYourAccountText = 'Acesse sua conta',
  noLoginYetText = 'Ainda não tem login?',
  registerActionText = 'Cadastrar',
  registerStepText = 'Cadastro',
  registerText = 'Cadastre-se',
  whichStoreWouldYouLinkText = 'De qual loja é a conta que você irá vincular?',
}
