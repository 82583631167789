import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, HostListener } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { ProfileEnum } from '../../shared/models/enums/profile.enum';
import { AuthTokenService } from '../../../services/auth-token.service';

@Component({
  selector: 'app-profile-dropdown',
  standalone: true,
  imports: [RouterLink, MatIconModule, NgClass, NgIf],
  templateUrl: './profile-dropdown.component.html',
  styleUrl: './profile-dropdown.component.scss',
})
export class ProfileDropdownComponent {
  isVisible = false;

  financialText = ProfileEnum.financialText;
  logoutText = ProfileEnum.logoutText;
  menuProfileOptionText = ProfileEnum.menuProfileOptionText;
  myAccountText = ProfileEnum.myAccountText;
  settingsText = ProfileEnum.settingsText;

  constructor(
    private authTokenService: AuthTokenService,
    private readonly router: Router,
    public elementRef: ElementRef
  ) {}

  /**
   * @description
   * Fecha o dropdown menu do perfil sempre o que usuário clicar fora do alvo.
   */
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isVisible = false;
    }
  }

  /**
   * @description
   * Chama o service de logout para encerrar a sessão do usuário.
   * Navega de voltar para o login.
   */
  logout() {
    this.authTokenService.logout();
  }
}
