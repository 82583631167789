import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ChartComponent } from 'ng-apexcharts';
import { MenuEnum } from '../../shared/models/enums/menu.enum';
import { ModalWhatsAppAccessComponent } from '../modal-whats-app-access/modal-whats-app-access.component';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    MatIconModule,
    NgIf,
    NgStyle,
    NgClass,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent {

  @Input() isMenuCollapsed!: boolean;
  @Output() toggleMenuEmitter = new EventEmitter();
  @ViewChild("chart") chart!: ChartComponent;

  accountsText = MenuEnum.accountsText;
  initText = MenuEnum.initText;
  metricsText = MenuEnum.metricsText;

  constructor(
    private readonly dialogRef: MatDialog
  ) { }

  /**
   * @description
   * Alterna entre menu expandido ou não enviando um evento para o componente pai.
   */
  toggleMenu() {
    this.toggleMenuEmitter.emit();
  }

  openWhatsAppAccessModal(): void {
    this.dialogRef.open(ModalWhatsAppAccessComponent)
  }
}
