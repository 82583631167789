<div class="input-container">
  <img src="{{iconInput}}" alt="{{label}}">

  <input
  matInput
  placeholder="{{ label }}"
  id="{{ id }}"
  [attr.type]="type"
  [formControl]="control"
  (focusout)="focusoutInput()"
  (keyup)="keyupInput()"
/>
</div>

@if(isValidCredentials) {
<div class="input-error">
  <mat-icon
    aria-hidden="false"
    [attr.aria-label]="errorText"
    fontIcon="error"
  ></mat-icon>
  <span>{{ incorrectCredentialsText }}</span>
</div>
}
