import { createAction, props } from '@ngrx/store';

// ----------- Formulário Detalhes da Conta
export const formDetailAction = createAction(
  '[Detail Form Action]',
  props<{
    id: string;
    name: string;
    status: string;
    user: string;
    externalId: string;
    fullName: string;
    marketplace: {
      id: number;
      name: string;
    };
    updatedAt: string;
  }>()
);

export const resetDetailFormAction = createAction('[Conta] Reset Detail Form');
