<div class="modal-delete-account">
    <div class="modal-header">
        <p>Excluir conta</p>
        <div aria-hidden="true" (click)="closeModal()">X</div>
    </div>
    <div class="modal-content">
        <div class="modal-content-info">
            <div>
                <h4>{{accountInfo.name}}</h4>
                <p>{{accountInfo.fullName}}</p>
            </div>
            <span class="sinc-status" [ngClass]="
        accountInfo.status === 'SYNCHRONIZED'
          ? '--success'
          : accountInfo.status === 'PENDING'
          ? '--pending'
          : '--error'
      ">
                {{
                accountInfo.status === "SYNCHRONIZED"
                ? "Sincronizada"
                : accountInfo.status === "PENDING"
                ? "Sincronizando"
                : "Falha na Sincronização"
                }}
            </span>
        </div>
        <span>Tem certeza que deseja excluir essa conta?</span>

        <div class="modal-content-btn-container">
            <div>
                <button (click)="closeModal()">CANCELAR</button>
            </div>
            <div aria-hidden="true" (click)="handleDeleteAccount()">
                <app-button text="Excluir" />
            </div>
        </div>
    </div>
</div>