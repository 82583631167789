<div class="profile-dropdown" [ngClass]="{ '--is-visible': isVisible }">
  <div class="profile-dropdown__head">
    <div class="profile-dropdown__head__avatar">
      <img src="../../../assets/images/icons/avatar.png" alt="avatar" />
    </div>
    <div class="profile-dropdown__head__label">
      <button
        (click)="this.isVisible = !this.isVisible"
        type="button"
        [attr.aria-label]="myAccountText"
      >
        <span>{{ myAccountText }}</span>
        <mat-icon
          aria-hidden="false"
          [attr.aria-label]="menuProfileOptionText"
          fontIcon="keyboard_arrow_down"
        ></mat-icon>
      </button>
    </div>
  </div>
  <div class="profile-dropdown__body" *ngIf="isVisible">
    <nav
      class="profile-dropdown__body__nav"
      aria-labelledby="profile-dropdown-navigation"
    >
      <ul>
        <li>
          <button
            type="button"
            class="profile-dropdown__body__nav__logout"
            (click)="logout()"
          >
            <i
              class="icon --logout"
              aria-hidden="false"
              [attr.aria-label]="logoutText"
            ></i>
            <strong>{{ logoutText }}</strong>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</div>
