<div class="app-header">
  <a [routerLink]="['/dashboard']">
    <div class="logo">
      <img
        src="../../../assets/images/icons/logo-capivarapro.svg"
        [attr.alt]="capivaraLogoText"
      />
    </div>
  </a>
  <div class="app-header__profile">
    <app-accounts-dropdown></app-accounts-dropdown>
    <app-profile-dropdown></app-profile-dropdown>
    <a
      class="app-header__profile__notifications"
      [routerLink]="['/notifications']"
    >
      <mat-icon
        aria-hidden="false"
        [attr.aria-label]="notificationsText"
        fontIcon="notifications"
      ></mat-icon>
    </a>
  </div>
</div>
