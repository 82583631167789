<div class="card">
  <div 
    class="card__heading" 
    aria-hidden="true"
    (click)="handleFormActions($event)"
  >
    <h3>{{ name }}</h3>
    <span
      class="sinc-status"
      [ngClass]="
        status === 'SYNCHRONIZED'
          ? '--success'
          : status === 'PENDING'
          ? '--pending'
          : '--error'
      "
      >{{
        status === "SYNCHRONIZED"
          ? "Sincronizada"
          : status === "PENDING"
          ? "Sincronizando"
          : "Falha na Sincronização"
      }}</span
    >
  </div>
  <div class="card__body">

    <div class="card-button__container">
      <div>
        <app-button
          [text]="'Excluir'"
          [fontIconName]="'delete'"
          (click)="openModalDialog($event)"
        />
      </div>

      <!-- ajustar pra mostrar apenas quando n for sincronizando -->
      @if (status === 'error') {
        <div class="button-sync" aria-hidden="true" (click)="syncAccount()">
          <app-button
            [text]="'Sincronizar'"
            [fontIconName]="'refresh'"
          />
        </div>
      }
    </div>
  </div>
</div>
