import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../../../services/accounts.service';
import { APIResponseDto, StoreDto } from '../../shared/models/dtos';
import { MatCheckbox } from '@angular/material/checkbox';
import { StoreService } from '../../../services/store.service';

/**
 * Accounts dropdown component.
 */
@Component({
  selector: 'app-accounts-dropdown',
  standalone: true,
  imports: [RouterLink, MatIconModule, NgClass, NgIf, MatCheckbox],
  templateUrl: './accounts-dropdown.component.html',
  styleUrl: './accounts-dropdown.component.scss',
})
export class AccountsDropdownComponent implements OnInit {

  /**
   * Stores.
   */
  public stores: StoreDto[] = [];

  /**
   * Dropdown visible.
   */
  public isDropDownVisible: boolean = false;

  /**
   * Dropdown text.
   */
  public dropDownText: string = 'Todas as contas';

  /**
   * Selected stores.
   */
  @Output()
  public selectedStores: StoreDto[] = [];

  /**
   * Accounts dropdown constructor.
   * 
   * @param {AccountService} accountService Account service.
   */
  constructor(
    private readonly accountService: AccountService,
    private readonly toastrService: ToastrService,
    private readonly elementRef: ElementRef,
    public readonly storeService: StoreService,
  ) { }

  /**
   * On init.
   */
  ngOnInit(): void {
    this.getStores();
  }

  /**
   * Capture click at dropdown.
   * 
   * @param {MouseEvent} event Mouse event.
   */
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropDownVisible = false;
    }
  }

  /**
   * Toggle dropdown visibility.
   */
  public toggleDropDown(): void {
    this.isDropDownVisible = !this.isDropDownVisible;
  }

  /**
   * 
   * @param {StoreDto} store Store.
   */
  public selectStore(store: StoreDto): void {
    const isSelected = this.storeService.selectedStores.includes(store);

    if (isSelected) {
      this.storeService.selectedStores = this.storeService.selectedStores.filter(s => s !== store);
    } else {
      this.storeService.selectedStores = [...this.storeService.selectedStores, store];
    }

    this.accountService.emitStoreSelectionChange(this.selectedStores);
  }

  /**
   * List of stores.
   */
  private getStores(): void {
    this.accountService
      .getAccounts()
      .subscribe((res: APIResponseDto<{ stores: StoreDto[] }>) => {
        if (res.message) {
          this.toastrService.error(res.message);
        } else {
          this.stores = res.body.stores;
        }
      });
  }
}
