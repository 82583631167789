<form (submit)="submitToML">
  <div class="btn-container-close-add-new" (click)="closeForm()">
    <img src="../../../assets/images/icons/back-btn-add-new.svg" alt="btn back">
    <button>
      Voltar
    </button>
  </div>
  
  <div class="form__input-box">
  <div class="container-input-add-new">
    <input type="text" [placeholder]="'Como vamos chamar essa conta?'" [(ngModel)]="nameAccount" name="nameAccount" required>
  </div>
  </div>
  <div class="form__external-link">
    <p>Para vincular suas contas autorize a conexão no Mercado Livre:</p>
    <div class="wrap-link-accounts">
      <p>
        <button (click)="submitToML()" class="form__external-link-button">
          <i class="icon --mercado-livre"></i>
          <span class="form__external-link-button-text">
            <strong>{{ connectMlText }}</strong>
            <mat-icon
              aria-hidden="false"
              [attr.aria-label]="openInNewText"
              fontIcon="open_in_new"
            ></mat-icon>
          </span>
        </button>
      </p>
    </div>
    
  </div>
  
</form>