import { Component, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { AuthEnum } from '../../shared/models/enums/auth.enum';
import { ButtonEnum } from '../../shared/models/enums/button.enum';
import { FormsEnum } from '../../shared/models/enums/forms.enum';
import { GlobalEnum } from '../../shared/models/enums/global';
import { InputEnum } from '../../shared/models/enums/input.enum';
import { ButtonComponent } from '../button/button.component';
import { InputComponent } from '../forms/input/input.component';
import { AuthService } from '../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { formVisibilityAction } from '../../../store/components/forms/forms.actions';
import { resetDetailFormAction } from '../../../store/contas/contas.actions';

@Component({
  selector: 'app-add-new',
  standalone: true,
  imports: [InputComponent, MatIconModule, ButtonComponent, FormsModule],
  templateUrl: './add-new.component.html',
  styleUrl: './add-new.component.scss',
})
export class AddNewComponent {
  public addNewForm!: FormGroup;
  nameAccount = '';
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private readonly store: Store<{
      contas: any;
      forms: any;
    }>,
  ) { }


  isActiveClassName = '--is-opened';
  isClosingClassName = '--is-closing';
  // Enums
  authorizeConnectionMLText = AuthEnum.authorizeConnectionMLText;
  formAccountDetailText = FormsEnum.formAccountDetailText;
  formAddNewText = FormsEnum.formAddNewText;
  goBackCloseFormText = FormsEnum.goBackCloseFormText;
  goBackText = GlobalEnum.goBackText;
  accountDetailText = FormsEnum.accountDetailText;
  synchronizeText = FormsEnum.synchronizeText;
  connectMlText = ButtonEnum.connectMlText;
  enterNoLinkAccountText = ButtonEnum.enterNoLinkAccountText;
  enterText = ButtonEnum.enterText;
  setSurnameText = InputEnum.setSurnameText;
  enterYourEmailText = InputEnum.enterYourEmailText;
  enterYourPasswordText = InputEnum.enterYourPasswordText;
  enterYourRegisterCodeText = InputEnum.enterYourRegisterCodeText;
  hintOutlineText = GlobalEnum.hintOutlineText;
  linkAccountsLaterText = AuthEnum.linkAccountsLaterText;
  openInNewText = ButtonEnum.openInNewText;
  whichStoreWouldYouLinkText = AuthEnum.whichStoreWouldYouLinkText;
  whichAccountNameText = InputEnum.whichAccountNameText;

  ngOnInit() {
    // FormGroup de cadastro.
    this.addNewForm = this.fb.group({
      surname: [''],
    });
  }

  isLoading: boolean = false;

  redirectToExternalUrl(url: string) {
    window.location.assign(url);
  }

  submitToML() {
    if (!this.nameAccount) {
      this.toastr.error('nome da conta obrigatório');
    } else {
      this.toastr.info('Redirecionando para o Mercado Livre...');
      this.authService.initAuthorizationMercadoLivre(this.nameAccount).subscribe(res => {
        const { error } = res;
  
        if (error) {
          const { message } = res;
  
          this.toastr.error(message || 'Erro ao carregar');
          this.isLoading = false;
  
          return;
        }
  
        const { detail } = res;
  
        if (detail) {
          this.toastr.error(detail);
          this.isLoading = false;
  
          return;
        }
  
        const { body } = res;
        this.redirectToExternalUrl(body.url);
      });
    }
  }

  closeForm() {
    this.renderer.removeClass(document.body, this.isActiveClassName);
    this.renderer.addClass(document.body, this.isClosingClassName);

    setTimeout(() => {
      this.renderer.removeClass(document.body, this.isClosingClassName);
      this.store.dispatch(formVisibilityAction({ isVisible: false }));
      this.store.dispatch(resetDetailFormAction());
    }, 200);
  }
}
