<div class="form" [ngClass]="formType === 'account-detail' ? '--account-detail' : '--add-new'">

  <ng-container *ngIf="formType === 'account-detail'">
    <div class="header-form">

      <div aria-hidden="true" (click)="closeForm()">
        <img src="../../../../assets/images/icons/back-btn-add-new.svg" alt="voltar">
      </div>
      <p>Detalhe da conta</p>

    </div>
    <div class="info-account-name">
      <div>
        <input type="text" [placeholder]="'Nome da conta'" [(ngModel)]="inputName" name="nameAccount"
          required>
          <!-- <h4>{{inputName}}</h4> -->
        <p>{{data.email}}</p>
      </div>
      <!-- <div (click)="updateName(inputName, data.id)">
        <img src="../../../../assets/images/icons/edit-name-account.svg" alt="editar">
      </div> -->
    </div>
    <div class="form__body">
      <div class="form__body__content">
      </div>
      <div class="form__body__content">
        <ul>
          <li>
            <h5>Status da Sincronização</h5>
            <p class="sinc-status" [ngClass]="
                data.status === 'SYNCHRONIZED'
                  ? '--success'
                  : data.status === 'PENDING'
                  ? '--pending'
                  : '--error'
              ">
              {{
              data.status === "SYNCHRONIZED"
              ? "Sincronizada"
              : data.status === "PENDING"
              ? "Sincronizando"
              : "Falha na Sincronização"
              }}
            </p>
          </li>
          <li>
            <h5>Última Atualização</h5>
            <p>{{updateAtFormatted}}</p>
          </li>

          <!-- @if (data.status !== 'PENDING') {
          } -->
          <li aria-hidden="true" [ngClass]="data.status !== 'PENDING' ? '--show-btn' : '--hide-btn'">
            <app-button (click)="syncAccount(data.id)" [text]="synchronizeText" [fontIconName]="'refresh'"></app-button>
          </li>
        </ul>
      </div>

    </div>
    <div class="btn-delete" aria-hidden="true" (click)="handleOpenModalDelete()">
      <app-button fontIconName="delete" text="Excluir conta" />
    </div>
  </ng-container>

  <ng-container *ngIf="formType !== 'account-detail'">
    <app-add-new></app-add-new>
  </ng-container>
</div>
<div class="form-backdrop" aria-hidden="true" (click)="closeForm()"></div>