import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { PATH_APPROVE_AUTHORIZATION_ML, PATH_INIT_AUTHORIZATION_ML } from '../assets/routes';
import { BaseService } from './base/base.service';

type BodyRequestType = {
  email: string;
  password: string;
  code: string;
}

type MlBodyRequest = {
  code: string;
  state: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  public initAuthorizationMercadoLivre(fromAccount?: string): Observable<any> {
    const accessToken = localStorage.getItem('access_token');

    if (!!fromAccount) {
      localStorage.setItem('UpdateAccountName', fromAccount);
    }

    return super.post(
      PATH_INIT_AUTHORIZATION_ML,
      {},
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    );
  }

  public aproveAuthorizationMercadoLivre({ code, state }: MlBodyRequest): Observable<any> {
    const accessToken = localStorage.getItem('access_token');

    return super.post(
      PATH_APPROVE_AUTHORIZATION_ML,
      {
        code,
        state
      },
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    );
  }

  public getStores(): Observable<any> {
    const accessToken = localStorage.getItem('access_token');

    return super.get(
      'v1/stores',
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    );
  }

  public saveStatusAuthorizationMercadoLivre(): void {
    sessionStorage.setItem('loginML', 'yes');
  }
}