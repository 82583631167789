<div class="menu">
  <button
    aria-labelledby="menu-collapse"
    class="menu__button-collapse"
    (click)="toggleMenu()"
  >
    <i class="icon --menu-collapse"></i>
  </button>
  <ul class="menu__list">
    <li>
      <a [routerLink]="['/dashboard']" [routerLinkActive]="'is-active'">
        <mat-icon
          aria-hidden="false"
          [attr.aria-label]="initText"
          fontIcon="home"
        ></mat-icon>
        <strong *ngIf="!isMenuCollapsed">{{ initText }}</strong>
      </a>
    </li>
    <li>
      <a [routerLink]="['/metricas']" [routerLinkActive]="'is-active'">
        <mat-icon
          aria-hidden="false"
          [attr.aria-label]="metricsText"
          fontIcon="bar_chart"
        ></mat-icon>
        <strong *ngIf="!isMenuCollapsed">{{ metricsText }}</strong>
      </a>
    </li>
    <li>
      <a [routerLink]="['/contas']" [routerLinkActive]="'is-active'">
        <mat-icon
          aria-hidden="false"
          [attr.aria-label]="accountsText"
          fontIcon="store"
        ></mat-icon>
        <strong *ngIf="!isMenuCollapsed">{{ accountsText }}</strong>
      </a>
    </li>
    <li>
      <a [routerLink]="['/pedidos']" [routerLinkActive]="'is-active'">
        <mat-icon
          aria-hidden="false"
          [attr.aria-label]="accountsText"
          fontIcon="local_grocery_store"
        ></mat-icon>
        <strong *ngIf="!isMenuCollapsed">Pedidos</strong>
      </a>
    </li>
    <li>
      <a (click)="openWhatsAppAccessModal()">
        <img class="icon-zap" src="../../../assets/images/icons/whatsapp.svg">
        <strong *ngIf="!isMenuCollapsed">Acesso via WhatsApp</strong>
      </a>
    </li>
  </ul>
</div>
